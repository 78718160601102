import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialThree() {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    margin: 30,
  };

  const TestimonilContent = [
    {
      desc: `With margins so tight, it just didn't cut into them more with pricy software and proprietary hardware. OpenStyle does the same thing (maybe even better), for a fraction of the cost.`,
      reviewerName: "Jannatul Ekra",
      designation: "Colorist",
    },
    {
      desc: `OpenStyle might just be the best investment we've ever made. We were losing a small fortune in product costs, which we've now turned into small fortune of profit.`,
      reviewerName: "Bostami Hasan",
      designation: "Owner, Bubbles Salon",
    },
    {
      desc: `When my colorist started using OpenStyle, I felt so much better about pricing now that I could see exactly where my money was going. I feel like [my colorist] was more careful with waste which is a win for everyone!`,
      reviewerName: "Bostami Hasan",
      designation: "Salon Customer",
    },
  ];

  return (
    <Slider {...settings}>
      {TestimonilContent.map((val, i) => (
        <div className="item" key={i}>
          <p>{val.desc}</p>
          <div className="name">{val.reviewerName}</div>
          <span>{val.designation}</span>
        </div>
      ))}
    </Slider>
  );
}
