import React from "react";
import ProductLandingDark from "./all-home-pages/ProductLandingDark";

const PreviewMain = () => {
  return (
    <div>
      <ProductLandingDark />
    </div>
  );
};

export default PreviewMain;
