import React from "react";

const FeatureContent = [
  {
    icon: "78",
    title: "Track By Weight",
    subTitle: `Know exactly how much to charge for the products you use.`,
    hoverText: `  Use any scale to calculate exact product costs - plus markup.`,
  },
  {
    icon: "79",
    title: "Track by Time",
    subTitle: `Know exactly how much to charge for your skilled labor.`,
    hoverText: `  Customize rates for each service or clients.`,
  },
  {
    icon: "80",
    title: "Your Assistant",
    subTitle: `OpenStyle handles clients and billing for you.`,
    hoverText: `  Easily access and share session summaries.`,
  },
];

const FancyFeatureTwelve = () => {
  return (
    <div className="row justify-content-center pt-50 md-pt-20">
      {FeatureContent.map((val, i) => (
        <div className="col-lg-4 col-md-6" key={i}>
          <div className="block-style-seventeen mt-40">
            <div className="icon d-flex align-items-center justify-content-center">
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <div className="static-text">
              <h3>{val.title}</h3>
              <p>{val.subTitle}</p>
            </div>
            <div className="hover-text">{val.hoverText}</div>
          </div>
          {/* /.block-style-seventeen */}
        </div>
      ))}
    </div>
    //   End .row
  );
};

export default FancyFeatureTwelve;
